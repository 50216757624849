import './App.css';


function App() {
  return (
    <div className="App">
      <MyComponent/>
    </div>
  );
}

const MyComponent = () => {
  // Inside a function or event handler
  window.location.href = "https://www.sage.com/en-ca/";


  // ... rest of your component logic
};


export default App;
